/* Through the magic of webpack jQuery is already exposed on global scope */
// require('jquery-ui-dist/jquery-ui.min'); // TODO: Find and remove SXA components depending in this

const $xa = jQuery;

$xa.shouldInitialize = function () {
    return typeof window !== 'undefined' && !!window?.Sitecore?.WebEditSettings?.editing;
};

window.$xa = $xa;
export default $xa;